/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~leaflet/dist/leaflet.css';

body {
  position: relative;
  color: white;
  height: 100vh;
  background-color: #333;
  font-family: Arial, Helvetica, Helvetica Neue, serif;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

/* Fix for withAuthenticator extra div */
#root > div {
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #fff;
}

p {
  font-size: 24px;
}

li {
  list-style: none;
}

a {
  color: white;
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #333333 inset;
}
input:-webkit-autofill:first-line {
  color: #ffffff;
}

.main-container {
  position: relative;
  margin: 15px;
}

::-webkit-scrollbar {
  display: none;
}

#canvas {
  margin-top: 10px;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
